import ApiService from "@/core/services/api.service";
import {API_URL} from '@/common/config.js'

const apiService = ApiService;


export default class AttachmentService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/attachment`;

    }

    paginate(data = null, index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    uploadAll(data) {
        let url = `${this.#api}/upload/all`
        return apiService.post(url, data);
    }

    byResource(id) {
            let url = `${API_URL}user/student/attachment/all/${id}/resource`
        return apiService.get(url);
    }

    getDownloadUrl(attachmentId){
        let url = `${API_URL}user/student/download/attachment/${attachmentId}`
        return apiService.get(url);
    }
}