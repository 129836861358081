<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <!--begin: Wizard Form-->
            <div class="row">
              <div class="col-6">
                <h4>
                  <router-link :to="{ name: 'library-resource-attachment-list', params: { id: resource_id }}">
                    <i class="fa fa-arrow-left" style="width: 40px"></i>
                  </router-link> Manage Attachment
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \ <router-link to="/resource">Resource</router-link> \
                <router-link :to="{ name: 'library-resource-attachment-list', params:{id:resource_id} }">
                  Attachment
                </router-link>
              </div>

              <div class="col-12 mt-2">
<!--                <iframe :src="attachment.display_path" allowTransparency="true" frameborder="0" toolbar="1"-->
<!--                        style="border:none; overflow:hidden; width: 100%; height: 1000px "></iframe>-->
                <embed :src="attachment.display_path" width="500" height="500">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import AttachmentService from "@/core/services/library/resource/AttachmentService";

const attachmentService = new AttachmentService();
export default {
  name: "view-attachment  ",
  data() {
    return {
      attachment: {}
    }
  },
  computed: {
    resource_id() {
      return this.$route.params.resource;
    },
    attachment_id() {
      return this.$route.params.attachment;
    }
  }, mounted() {
    this.getAttachment()
  },
  methods: {
    getAttachment() {
      attachmentService.show(this.attachment_id).then(response => {
        this.attachment = response.data.attachment
        // console.log(this.attachment)
      })
    }
  }
}
</script>

<style scoped>

</style>
